@import './assets/global-styles/breakpoints.scss';

/* Resets */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
textarea {
    resize: none;
}
a[href]{
    color: var(--link-color);
}
body{
    font-family: 'Poppins';
}

/* Variables */
:root {
    --primary-color: #EB5E00;
    --blue-primary-color: #25293b;
    --secondary-color: #fff;

    --header-bg: #F2F4F5;
    --header-color1: #000000;
    --header-color2: #EB5E00;



    --footer-bg: #F2F4F5;
    --footer-color: #000000;

    --header-height: 60px;
    --header-mobile-height: 50px;


    --deal-body-bg: #F8F7FC;
    --deal-body-secondary-bg: #fff;

    --font-sizeXXL: 26px;
    --font-sizeXL: 20px;
    --font-sizeL: 16px;

    --font-color1: #25293B;
    --font-color2: #707C8B;
    --font-color3: rgba(37,41,59,0.3);
    --link-color: #75CC00;
    --error-color: #FF8F8F;
    --success-color: #6ECD74;

    --button-sizeXL: 70px;
    --button-fontXL: 20px;
    --button-sizeL: 50px;
    --button-fontL: 16px;
    --button-sizeM: 40px;
    --button-fontM: 14px;
    --button-fontXS: 10px;
    --button-sizeXS: 30px;

    --button-primary-bg-color: #FF9228;
    --button-primary-outline-bg-color: #EB5E00;
    --button-primary-hover-bg-color:#DB5E00;
    --button-primary-active-bg-color:#CB5E00;
    --button-blue-primary-bg-color: #25293b;
    --button-blue-primary-outline-bg-color: #25293b;
    --button-blue-primary-hover-bg-color: #111111;
    --button-blue-primary-active-bg-color:#25293b;
    --button-tertiary-bg-color: #526FFF;

    --button-bg-color: #fff;
    --button-hover-bg-color:#f7f7f7;
    --button-active-bg-color:#e3e3e3;

    --button-primary-outline-color: #EB5E00;
    --button-blue-primary-outline-color: #25293b;
    --button-primary-color: #fff;
    --button-secondary-color: #526EFF;

}

/* Fonts */
@font-face {
    font-family: 'Poppins';
    font-weight: 100;
    src: local('Poppins'), url(./assets/fonts/poppins/Poppins-Light.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(./assets/fonts/poppins/Poppins-Regular.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    font-weight: 600;
    src: local('Poppins'), url(./assets/fonts/poppins/Poppins-Medium.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    font-weight: 800;
    src: local('Poppins'), url(./assets/fonts/poppins/Poppins-SemiBold.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    font-weight: bold;
    src: local('Poppins'), url(./assets/fonts/poppins/Poppins-Bold.ttf) format('truetype');
    font-display: swap;
}


/* icons */

@font-face {
    font-family: 'icomoon';
    src:  url('./assets/fonts/icomoon/icomoon.eot?irbhre');
    src:  url('./assets/fonts/icomoon/icomoon.eot?irbhre#iefix') format('embedded-opentype'),
      url('./assets/fonts/icomoon/icomoon.ttf?irbhre') format('truetype'),
      url('./assets/fonts/icomoon/icomoon.woff?irbhre') format('woff'),
      url('./assets/fonts/icomoon/icomoon.svg?irbhre#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
  
[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.icon-TikMark:before {
    content: "\e904";
}
  
.icon-Down:before {
    content: "\e900";
}

.icon-Search:before {
    content: "\e901";
}

.icon-StarFill:before {
    content: "\e902";
}

.icon-StarOutline:before {
    content: "\e903";
}
  


/* Defaults */

.underline{
    text-decoration: underline;
}

.strike{
    text-decoration: line-through;
}

.capitalize{
    text-transform: capitalize;
}

.flex{
    display: flex;
}

.flex-wrap{
    flex-wrap: wrap;
}

.flex-col{
    flex-direction: column;
}

.flex-1{
    flex: 1;
}
.flex-2{
    flex: 2;
}

.flex-auto{
    flex: auto;
}



.justify-center{
    justify-content: center;
}
.justify-space-between{
    justify-content: space-between;
}

.items-center{
    align-items: center;
}

.align-self-end{
    align-self: flex-end;
}

.justify-self-end{
    justify-self: flex-end;
}
.bold{
    font-weight: bold;
}
.overflow-hidden{
    overflow: hidden;
}
.upper-case{
    text-transform: uppercase;
}

.responsive-image{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.container{
    max-width: 1288px;
    margin: 0 auto;
    padding: 0;
    @media only screen and (max-width: $mobileBreakpoint) {
        padding: 0 16px;
    }
    @media only screen and ( min-width: $mobileBreakpoint ) and (max-width: $smallDesktopBreakpoint) {
        padding: 0 24px;
    }
}

.text-right{
    text-align: right;
}

.text-center {
    text-align: center;
}

.h-full-vh{
    height: 100vh;
}

.h-full{
    height: 100%;
}
/* Width Utilities */
.w-full{
    width: 100%;
}

.w-auto {
    width: auto !important;
}

.mt-8px {
    margin-top: 8px;
}

.mt-16px {
    margin-top: 16px;
}

.mb-16px{
    margin-bottom: 16px;
}

.mr-8px{
    margin-right: 8px;
}

.mr-32px{
    margin-right: 32px;
}

.mr-40px{
    margin-right: 32px;
}

.relative{
    position: relative;
}

.margin-0-auto {
    margin: 0 auto !important;
}

.margin-0 {
    margin: 0 !important;
}

.margin-left-auto{
    margin-left: auto;
}

/* Display Utilities */
.d-none {
    display: none;
}
.d-inline-flex {
    display: inline-flex !important;
}

/* Background Color Utilities*/
.deal-body-secondary-bg {
    background: var(--deal-body-secondary-bg) !important;
}

/* Box Shadow None */
.box-shadow-none {
    box-shadow: none !important;
}

/* Cursor Utilities */
.cursor-pointer {
    cursor: pointer
}

.font-white{
    color: #fff;
}
